import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
// import Socket from "./Sockets/socket";

// import axios from 'axios'

Vue.use(Router)
let router = new Router({
	mode: 'history',
	routes: [

		{
			path: '/admin',
			props: true,
			component: () => import('./layouts/admin'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'adminDashboard',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: '',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},
				


				{
					path: 'conflictReports',
					name: 'conflictReports',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ConflictReports/conflictReports')
				},
				{
					path: 'emergencyUser',
					name: 'emergencyUser',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EmergencyUser/adduser')
				},
				{
					path: 'reportCase',
					name: 'reportCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReportCase/reportCase')
				},
				{
					path: 'resolveCase',
					name: 'resolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ResolveCase/resolveCase')
				},
				{
					path: 'releaseCase',
					name: 'releaseCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReleaseCase/releaseCaseAdmin')
				},
				{
					path: 'myreport',
					name: 'myreport',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/MyReports/myReport')
				},
				{
					path: 'closedcases',
					name: 'closedcases',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ClosedCases/closed')
				},
				{
					path: 'mergedlist',
					name: 'mergedlist',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/MergedCases/merged')
				},
				{
					path: 'editresolveCase',
					name: 'editresolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EditResolve/resolveEditadmin')
				},
				{
					path: 'public',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'public',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/public')
						},
						{

							path: 'reports',
							name: 'reports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/reports')
						},
					],
				},
				{
					path: 'mergeCases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: "",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/MergeCases/mergeCases'),
							props: true,
						},
						{
							path: "mergeCaseSuggestion",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/MergeCases/mergeCaseSuggestion'),
							props: true,
						}
					]
				},
				{
					path: 'dynamicData',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: 'scenerios',
							name: 'scenerios',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Scenerios/scenerios')
						},

						{

							path: 'animals',
							name: 'animals',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Animals/Animals')
						},
						{

							path: 'animalActivities',
							name: 'animalActivities',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalActivities/animalActivities')
						},
						{

							path: 'animalAge',
							name: 'animalAge',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalAge/animalAge')
						},
						{

							path: 'animalStatus',
							name: 'animalStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalStatus/animalStatus')
						},
						{

							path: 'gender',
							name: 'gender',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Gender/gender')
						},

						{

							path: 'mitigationTypes',
							name: 'mitigationType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationTypes')
						},
						{

							path: 'mitigationMethods',
							name: 'mitigationMethod',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationMethods')
						},
						{

							path: 'releaseTypes',
							name: 'releaseType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ReleaseTypes/releaseTypes')
						},
						{

							path: 'landTypes',
							name: 'landType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/LandTypes/landTypes')
						},
						{

							path: 'breachType',
							name: 'breachType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BreachType/breachType')
						},
						{

							path: 'barrierType',
							name: 'barrierType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BarrierType/barrierType')
						},
						{

							path: 'conflictType',
							name: 'conflictType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ConflictType/conflictType')
						},
						{

							path: 'cropType',
							name: 'cropType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CropType/cropType')
						},
						{

							path: 'veterinaryRecommendation',
							name: 'veterinaryRecommendation',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/VeterinaryRecommendation/veterinaryRecommendation')
						},
						{

							path: 'closeStatus',
							name: 'closeStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CloseStatus/closeStatus')
						},
					],
				},
				{
					path: 'sarpa',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: '/Sarpa/dashboard',
							name: 'sarpadashboard',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/dashboard')
						},
						// add venom
						{

							path: '/listVenom',
							name: 'listVenom',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Hospital/listVenom.vue')
						},
						{

							path: '/topcommonsnakes',
							name: 'topcommonsnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/topSnakes.vue')
						},
						{

							path: '/usermap',
							name: 'usermap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/userMap.vue')
						},
						{

							path: '/rescuermap',
							name: 'rescuermap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/rescuerMap.vue')
						},
						{

							path: '/hospitalMap',
							name: 'hospitalMap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/hospitalMap.vue')
						},
						{

							path: '/listedSnakes',
							name: 'listedSnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/listedSnakes.vue')
						},
						{

							path: '/editSnake',
							name: 'editSnake',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/editSnake.vue')
						},
						{

							path: '/snakedetails',
							name: 'snakedetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/snakeDetails.vue')
						},
						{

							path: '/snakebiteDeath',
							name: 'snakebiteDeath',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/snakebiteDeath.vue')
						},
						{

							path: '/snakerescued',
							name: 'snakerescued',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/snakeRescuedmap.vue')
						},
						{

							path: '/topUsers',
							name: 'topUsers',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/topUsers.vue')
						},
						{

							path: '/topUsersyear',
							name: 'topUsersyear',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/topUsersyear.vue')
						},
						{

							path: '/addSnakes',
							name: 'addSnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Addsnakes/addSnakes.vue')
						},
						{

							path: '/usersList',
							name: 'usersList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/UserList/usersList.vue')
						},
						{

							path: '/edituser',
							name: 'edituser',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/UserList/editUser.vue')
						},
						{

							path: '/newrescuerslist',
							name: 'newrescuerslist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/newList.vue')
						},
						{

							path: '/newrescuerslistcircle',
							name: 'newrescuerslistcircle',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/newListCircle.vue')
						},
						{

							path: '/newrescuerslistview',
							name: 'newrescuerslistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/viewDetails.vue')
						},
						{

							path: '/newrescuerslistedit',
							name: 'newrescuerslistedit',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/editDetails.vue')
						},
						{

							path: '/allrescuerlist',
							name: 'allrescuerlist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuerList/alluserlist.vue')
						},
						{

							path: '/dfoList',
							name: 'dfoList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DFO/dfoList.vue')
						},
						{

							path: '/controlRoomList',
							name: 'controlRoomList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Control Room/controlRoomList.vue')
						},
						{

							path: '/alldmoList',
							name: 'alldmoList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DmoList/alldmoList.vue')
						},
						{

							path: '/alldistrictList',
							name: 'alldistrictList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DistrictofficerList/alldistrictList.vue')
						},
						{

							path: '/imageList',
							name: 'imageList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Images/imageList.vue')
						},
						{

							path: '/videoList',
							name: 'videoList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Videos/videoList.vue')
						},
						{
							

							path: '/rescuerslistview',
							name: 'rescuerslistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuerList/viewDetails.vue')
						},
						{

							path: '/pendingoperations',
							name: 'pendingoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/PendingOperations/pending.vue')
						},
						{

							path: '/viewpendingoperations',
							name: 'viewpendingoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/PendingOperations/viewPending.vue')
						},
						{

							path: '/assignedoperations',
							name: 'assignedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AssignedOperations/list.vue')
						},
						{

							path: '/excelreport',
							name: 'excelreport',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Excel/excelReport.vue')
						},
						{

							path: '/assignedoperationsview',
							name: 'assignedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AssignedOperations/view.vue')
						},
						{

							path: '/rescuedoperations',
							name: 'rescuedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuedOperations/list.vue')
						},
						{

							path: '/rescuedoperationsview',
							name: 'rescuedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuedOperations/view.vue')
						},
						{

							path: '/releasedoperations',
							name: 'releasedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ReleasedOperations/list.vue')
						},
						{

							path: '/releasedoperationsview',
							name: 'releasedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ReleasedOperations/view.vue')
						},
						{

							path: '/approvedoperationsview',
							name: 'approvedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApprovedOperations/view.vue')
						},
						{

							path: '/approvedoperations',
							name: 'approvedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApprovedOperations/list.vue')
						},
						{

							path: '/approvedoperationsview',
							name: 'approvedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApprovedOperations/view.vue')
						},
						{

							path: '/deleteoperations',
							name: 'deleteoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DeleteOperations/list.vue')
						},

						{

							path: '/fraudcases',
							name: 'fraudcases',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/FraudCases/fraudcases.vue')
						},
						{

							path: '/viewFraudCase',
							name: 'viewFraudCase',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/FraudCases/viewFraudCase.vue')
						},

						{

							path: '/deleteoperationsview',
							name: 'deleteoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DeleteOperations/view.vue')
						},
						{

							path: '/traininglist',
							name: 'traininglist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/TrainingList/list.vue')
						},
						{

							path: '/traininglistview',
							name: 'traininglistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/TrainingList/view.vue')
						},
						{

							path: '/snakereports',
							name: 'snakereports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/SnakeReports/list.vue')
						},
						{

							path: '/snakereportsview',
							name: 'snakereportsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/SnakeReports/view.vue')
						},
						{

							path: '/addsnakeexperts',
							name: 'addsnakeexperts',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AddSnakeExperts/add.vue')
						},
						{

							path: '/addhospital',
							name: 'addhospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AddHospital/add.vue')
						},
						{

							path: '/Viewhospital',
							name: 'Viewhospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ViewHospital/list.vue')
						},
						{

							path: '/edithospital',
							name: 'edithospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ViewHospital/edit.vue')
						},
						{

							path: '/identificationhelp',
							name: 'identificationhelp',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Identification/help.vue')
						},
						{

							path: '/snakeBite',
							name: 'snakeBite',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/SnakeBite/snakeBite.vue')
						},
						{

							path: '/viewSnakeBite',
							name: 'viewSnakeBite',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/SnakeBite/viewSnakeBite.vue')
						},
						{

							path: '/victimDetails',
							name: 'victimDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Hospital/victimDetails.vue')
						},
						{

							path: '/identificationhelpview',
							name: 'identificationhelpview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Identification/helpview.vue')
						},
						{

							path: '/approvehospital',
							name: 'approvehospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApproveHospital/list.vue')
						},
						{

							path: '/reportGeneration',
							name: 'reportGeneration',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/FilterData/filterData.vue')
						},
						

						
					],
				},
				{
					path: 'cases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: 'caseDetails',
							name: 'caseDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Cases/caseDetails')
						},
					],
				},
				{
					path: 'notifications',
					name: 'notification',
					props: true,
					component: () => import('./views/Notifications/notifications'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analytics',
					name: 'Analytics',
					props: true,
					component: () => import('./views/Analytics/analytics'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analyticspage',
					name: 'analyticspage',
					props: true,
					component: () => import('./views/Analytics/newAnalytics'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'commonanalyticspage',
					name: 'commonanalyticspage',
					props: true,
					component: () => import('./views/Analytics/commonDash'),
					meta: {
						requiresAuth: true,
					}
				},
			]
		},
		{
			path: '/emergencyuser',
			props: true,
			component: () => import('./layouts/emergencyuser'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'adminDashboard',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: '',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},

				{
					path: 'conflictReports',
					name: 'conflictReports',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ConflictReports/conflictReports')
				},
				{
					path: 'emergencyUser',
					name: 'emergencyUser',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EmergencyUser/adduser')
				},
				{
					path: 'reportCase',
					name: 'reportCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReportCase/reportCase')
				},
				{
					path: 'resolveCase',
					name: 'resolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ResolveCase/resolveCase')
				},
				{
					path: 'releaseCase',
					name: 'releaseCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReleaseCase/releaseCase')
				},
				{
					path: 'myreport',
					name: 'myreport',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/MyReports/myReport')
				},
				{
					path: 'public',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'public',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/public')
						},
						{

							path: 'reports',
							name: 'reports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/reports')
						},
					],
				},
				{
					path: 'dynamicData',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: 'scenerios',
							name: 'scenerios',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Scenerios/scenerios')
						},

						{

							path: 'animals',
							name: 'animals',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Animals/Animals')
						},
						{

							path: 'animalActivities',
							name: 'animalActivities',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalActivities/animalActivities')
						},
						{

							path: 'animalAge',
							name: 'animalAge',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalAge/animalAge')
						},
						{

							path: 'animalStatus',
							name: 'animalStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalStatus/animalStatus')
						},
						{

							path: 'gender',
							name: 'gender',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Gender/gender')
						},

						{

							path: 'mitigationTypes',
							name: 'mitigationType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationTypes')
						},
						{

							path: 'mitigationMethods',
							name: 'mitigationMethod',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationMethods')
						},
						{

							path: 'releaseTypes',
							name: 'releaseType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ReleaseTypes/releaseTypes')
						},
						{

							path: 'landTypes',
							name: 'landType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/LandTypes/landTypes')
						},
						{

							path: 'breachType',
							name: 'breachType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BreachType/breachType')
						},
						{

							path: 'barrierType',
							name: 'barrierType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BarrierType/barrierType')
						},
						{

							path: 'conflictType',
							name: 'conflictType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ConflictType/conflictType')
						},
						{

							path: 'cropType',
							name: 'cropType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CropType/cropType')
						},
						{

							path: 'veterinaryRecommendation',
							name: 'veterinaryRecommendation',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/VeterinaryRecommendation/veterinaryRecommendation')
						},
						{

							path: 'closeStatus',
							name: 'closeStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CloseStatus/closeStatus')
						},
					],
				},
				{
					path: 'sarpa',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: '/Sarpa/dashboard',
							name: 'sarpadashboard',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/dashboard')
						},

						
					],
				},
				{
					path: 'cases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: 'caseDetails',
							name: 'caseDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Cases/caseDetails')
						},
					],
				},
				{
					path: 'notifications',
					name: 'notification',
					props: true,
					component: () => import('./views/Notifications/notifications'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analytics',
					name: 'Analytics',
					props: true,
					component: () => import('./views/Analytics/analytics'),
					meta: {
						requiresAuth: true,
					}
				},
			]
		},
		{
			path: '/officers',
			props: true,
			component: () => import('./layouts/officers'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{

					path: 'allrescuerlistOfficers',
					name: 'allrescuerlistOfficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/RescuerList/alluserlist_OfficersView.vue')
				},
				{

					path: 'excelreport',
					name: 'excelreport',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Excel/excelReport.vue')
				},
				{

					path: 'alldistrictList',
					name: 'alldistrictList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/DistrictofficerList/alldistrictList.vue')
				},
				{

					path: 'controlRoomList',
					name: 'controlRoomList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Control Room/controlRoomList.vue')
				},
				{

					path: 'viewSnakeBiteOfficers',
					name: 'viewSnakeBiteOfficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/SnakeBite/viewSnakeBite.vue')
				},
				{

					path: 'snakeBiteOfficers',
					name: 'snakeBiteOfficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/SnakeBite/snakeBiteOfficers.vue')
				},
				// {

				// 	path: 'allrescuerlist',
				// 	name: 'allrescuerlist',
				// 	meta: {
				// 		requiresAuth: true,
				// 	},
				// 	component: () => import('./views/Sarpa/RescuerList/alluserlist.vue')
				// },
				{

					path: 'officetraininglist',
					name: 'officetraininglist',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/TrainingList/listOfficers.vue')
				},
				{

					path: 'traininglistviewOfficers',
					name: 'traininglistviewOfficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/TrainingList/viewOfficers.vue')
				},
				
				{

					path: 'fraudcases',
					name: 'fraudcases',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/FraudCases/fraudcases.vue')
				},
				{

					path: 'alldmoList',
					name: 'alldmoList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/DmoList/alldmoList.vue')
				},
				
				{
							

					path: 'rescuerslistview',
					name: 'rescuerslistview',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/RescuerList/viewDetails.vue')
				},
				{

					path: 'newrescuerslist',
					name: 'newrescuerslist',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/NewRescuers/newListRangeOfficers.vue')
				},

				
				{

					path: 'newrescuerslistviewRangeOfficers',
					name: 'newrescuerslistviewRangeOfficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/NewRescuers/viewDetailsRangeOfficers.vue')
				},

					// operations in officers
					{

						path: 'pendingoperations',
						name: 'pendingoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/PendingOperations/pendingOfficers.vue')
					},
					{

						path: 'officeviewpendingoperations',
						name: 'officeviewpendingoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/PendingOperations/viewPending.vue')
					},
					{

						path: 'assignedoperations',
						name: 'assignedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/AssignedOperations/listOfficers.vue')
					},
					{

						path: 'officeassignedoperationsview',
						name: 'officeassignedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/AssignedOperations/view.vue')
					},
					{

						path: 'rescuedoperations',
						name: 'rescuedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/RescuedOperations/listOfficers.vue')
					},
					{

						path: 'officerescuedoperationsview',
						name: 'officerescuedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/RescuedOperations/view.vue')
					},
					{

						path: 'releasedoperations',
						name: 'releasedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ReleasedOperations/listOfficers.vue')
					},
					{

						path: 'officereleasedoperationsview',
						name: 'officereleasedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ReleasedOperations/view.vue')
					},

					{

						path: 'approvedoperations',
						name: 'approvedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ApprovedOperations/listOfficers.vue')
					},
					{

						path: 'officeapprovedoperationsview',
						name: 'officeapprovedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ApprovedOperations/view.vue')
					},
					{

						path: 'deleteoperations',
						name: 'deleteoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/DeleteOperations/listOfficers.vue')
					},


					{

						path: 'officedeleteoperationsview',
						name: 'officedeleteoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/DeleteOperations/view.vue')
					},



				{
					path: 'officeDashboard',
					name: 'officeDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/officeDashboard')
				},
				{
					path: 'closedcases',
					name: 'closedcases',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ClosedCases/closed')
				},

				{

					path: '/officeusermap',
					name: 'officeusermap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/userMap.vue')
				},
				{

					path: '/officetopcommonsnakes',
					name: 'officetopcommonsnakes',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/topSnakes.vue')
				},
				{

					path: '/officetopUsers',
					name: 'officetopUsers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/topUsers.vue')
				},
				{

					path: '/officeusersList',
					name: 'officeusersList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/UserList/usersList.vue')
				},
				{

					path: '/officerescuermap',
					name: 'officerescuermap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/rescuerMap.vue')
				},
{

					path: '/officesnakerescued',
					name: 'officesnakerescued',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/snakeRescuedmap.vue')
				},
{

					path: '/officeidentificationhelp',
					name: 'officeidentificationhelp',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Identification/helpListOfficersView.vue')
				},
				{

					path: '/officeidentificationhelpview',
					name: 'officeidentificationhelpview',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Identification/helpviewOfficer.vue')
				},

				{

					path: '/officehospitalMap',
					name: 'officehospitalMap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/hospitalMap.vue')
				},
{

					path: '/officesnakebiteDeath',
					name: 'officesnakebiteDeath',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/snakebiteDeath.vue')
				},
				{

					path: '/officelistedSnakes',
					name: 'officelistedSnakes',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/listedSnakes.vue')
				},






				{
					path: '',
					name: 'dashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboard')
				},

				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},
				{
					path: 'resolveCase',
					name: 'resolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ResolveCaseOfficer/ResolveCaseOfficer')
				},
				{
					path: 'editresolveCase',
					name: 'editresolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EditResolve/resolveEdit')
				},
				{

					path: 'reportGenerationofficers',
					name: 'reportGenerationofficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/FilterData/filterDataofficer.vue')
				},

				{
					path: 'conflictReports',
					name: 'conflictReports',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ConflictReports/conflictReports')
				},
				{
					path: 'officers',
					name: 'officersSection',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Officers/Officers/officers')
				},
				{
					path: 'public',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'public',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Public/public')
						},
						// {

						// 	path: 'reports',
						// 	name: 'reports',
						// 	meta: {
						// 		requiresAuth: true,
						// 	},
						// 	component: () => import('./views/Admin/Public/reports')
						// },
					],
				},
				{
					path: 'cases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: 'caseDetails',
							name: 'caseDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Cases/caseDetails')
						},
					],
				},
				{

					path: 'sarpadashboard',
					name: 'sarpadashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/officeDashboard')
				},
				{
					path: 'mergeCases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: "",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/MergeCases/mergeCases'),
							props: true,
						},
						{
							path: "mergeCaseSuggestion",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/MergeCases/mergeCaseSuggestion'),
							props: true,
						}
					]
				},
				{
					path: 'notifications',
					name: 'notification',
					props: true,
					component: () => import('./views/Notifications/notifications'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analytics',
					name: 'Analytics',
					props: true,
					component: () => import('./views/Analytics/newAnalytics'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'reportcase',
					name: 'reportcase',
					props: true,
					component: () => import('./views/ReportCase/reportCase'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'resolvecase',
					name: 'resolvecase',
					props: true,
					component: () => import('./views/ResolveCase/resolveCase'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'releasecase',
					name: 'releasecase',
					props: true,
					component: () => import('./views/ReleaseCase/releaseCase'),
					meta: {
						requiresAuth: true,
					}
				},


			]
		},
{
			path: '/medicalOfficers',
			props: true,
			component: () => import('./layouts/medicalOfficers'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'dmoDashboard',
					name: 'dmoDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/dmoDashboard')
				},
				{
					path: '',
					name: 'dashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboard')
				},
				{

					path: '/dmousermap',
					name: 'dmousermap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/userMap.vue')
				},
				{

					path: '/dmotopcommonsnakes',
					name: 'dmotopcommonsnakes',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/topSnakes.vue')
				},
				{

					path: '/dmotopUsers',
					name: 'dmotopUsers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/topUsers.vue')
				},
				{

					path: '/dmousersList',
					name: 'dmousersList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/UserList/usersList.vue')
				},
				{

					path: '/dmorescuermap',
					name: 'dmorescuermap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/rescuerMap.vue')
				},
{

					path: '/dmosnakerescued',
					name: 'dmosnakerescued',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/snakeRescuedmap.vue')
				},
{

					path: '/dmoidentificationhelp',
					name: 'dmoidentificationhelp',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Identification/helpListDMO.vue')
				},
				{

					path: '/dmoidentificationhelpview',
					name: 'dmoidentificationhelpview',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Identification/helpviewDMO.vue')
				},

				{

					path: '/dmohospitalMap',
					name: 'dmohospitalMap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/hospitalMap.vue')
				},
{

					path: '/dmosnakebiteDeath',
					name: 'dmosnakebiteDeath',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/snakebiteDeath.vue')
				},
				{

					path: '/dmolistedSnakes',
					name: 'dmolistedSnakes',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/listedSnakes.vue')
				},



				{
					path: 'stockVenom',
					name: 'stockVenom',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Hospital/stockVenom')
				},
				{
					path: 'victimDetails',
					name: 'victimDetails',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Hospital/victimDetails')
				},


				{

					path: 'sarpadashboard',
					name: 'sarpadashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/dmoDashboard')
				},
				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},
				


			]
		},



		{
			path: '/DistrictEmergencyOfficer',
			props: true,
			component: () => import('./layouts/DistrictEmergencyOfficer'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'districtDashboard',
					name: 'districtDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/districtDashboard')
				},
				{
					path: '',
					name: 'dashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboard')
				},
				{

					path: '/disusermap',
					name: 'disusermap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/userMap.vue')
				},
				{

					path: '/distopcommonsnakes',
					name: 'distopcommonsnakes',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/topSnakes.vue')
				},
				{

					path: '/distopUsers',
					name: 'distopUsers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/topUsers.vue')
				},
				{

					path: '/disusersList',
					name: 'disusersList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/UserList/usersList.vue')
				},
				{

					path: '/disrescuermap',
					name: 'disrescuermap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/rescuerMap.vue')
				},
{

					path: '/dissnakerescued',
					name: 'dissnakerescued',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/snakeRescuedmap.vue')
				},
{

					path: '/disidentificationhelp',
					name: 'disidentificationhelp',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Identification/helpListDIS.vue')
				},
				{

					path: '/disidentificationhelpview',
					name: 'disidentificationhelpview',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Identification/helpviewDIS.vue')
				},

				{

					path: '/dishospitalMap',
					name: 'dishospitalMap',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/hospitalMap.vue')
				},
{

					path: '/dissnakebiteDeath',
					name: 'dissnakebiteDeath',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/snakebiteDeath.vue')
				},
				{

					path: '/dislistedSnakes',
					name: 'dislistedSnakes',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/listedSnakes.vue')
				},


				// otherpages sidebar disemergencyofficers
				{

					path: 'allrescuerlistDis',
					name: 'allrescuerlistDis',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/RescuerList/alluserlist_OfficersView.vue')
				},
								{

					path: 'controlRoomList',
					name: 'controlRoomList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Control Room/controlRoomList.vue')
				},
				{

					path: 'viewSnakeBiteDis',
					name: 'viewSnakeBiteDis',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/SnakeBite/viewSnakeBite.vue')
				},
				{

					path: 'snakeBiteDis',
					name: 'snakeBiteDis',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/SnakeBite/snakeBiteDis.vue')
				},
				// {

				// 	path: 'allrescuerlist',
				// 	name: 'allrescuerlist',
				// 	meta: {
				// 		requiresAuth: true,
				// 	},
				// 	component: () => import('./views/Sarpa/RescuerList/alluserlist.vue')
				// },
								


				
				{

					path: 'fraudcases',
					name: 'fraudcases',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/FraudCases/fraudcasesDis.vue')
				},
				{

					path: 'DisviewFraudCase',
					name: 'DisviewFraudCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/FraudCases/viewFraudCase.vue')
				},
				{

					path: 'alldmoList',
					name: 'alldmoList',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/DmoList/alldmoList.vue')
				},
				
				{
							

					path: 'rescuerslistview',
					name: 'rescuerslistview',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/RescuerList/viewDetails.vue')
				},
							
					// operations in officers
					{

						path: 'pendingoperations',
						name: 'pendingoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/PendingOperations/pendingDis.vue')
					},
					{

						path: 'Disviewpendingoperations',
						name: 'Disviewpendingoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/PendingOperations/viewPendingDis.vue')
					},
					{

						path: 'assignedoperations',
						name: 'assignedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/AssignedOperations/listDis.vue')
					},
					{

						path: 'Disassignedoperationsview',
						name: 'Disassignedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/AssignedOperations/viewDis.vue')
					},
					{

						path: 'rescuedoperations',
						name: 'rescuedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/RescuedOperations/listDis.vue')
					},
					{

						path: 'Disrescuedoperationsview',
						name: 'Disrescuedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/RescuedOperations/viewDis.vue')
					},
					{

						path: 'releasedoperations',
						name: 'releasedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ReleasedOperations/listDis.vue')
					},
					{

						path: 'Disreleasedoperationsview',
						name: 'Disreleasedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ReleasedOperations/viewDis.vue')
					},

					{

						path: 'approvedoperations',
						name: 'approvedoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ApprovedOperations/listDis.vue')
					},
					{

						path: 'Disapprovedoperationsview',
						name: 'Disapprovedoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/ApprovedOperations/viewDis.vue')
					},
					{

						path: 'deleteoperations',
						name: 'deleteoperations',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/DeleteOperations/listDis.vue')
					},


					{

						path: 'Disdeleteoperationsview',
						name: 'Disdeleteoperationsview',
						meta: {
							requiresAuth: true,
						},
						component: () => import('./views/Sarpa/DeleteOperations/viewDis.vue')
					},




				

				{

					path: 'sarpadashboard',
					name: 'sarpadashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Sarpa/Dashboard/districtDashboard')
				},
				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},
				


			]
		},



		
		// {
		// 	path: '/',
		// 	props: true,
		// 	component: () => import('./layouts/authentication'),
		// 	meta: {
		// 		requiresAuth: false,
		// 	},
		// 	children: [
		// 		{
		// 			path: 'login',
		// 			name: 'login',
		// 			meta: {
		// 				requiresAuth: false,
		// 			},
		// 			component: () => import('./layouts/authentication')
		// 		},
		// 	]
		// },
		{
			path: '/',
			props: true,
			component: () => import('./layouts/authenticationTest'),
			meta: {
				requiresAuth: false,
			},
			children: [
				{
					path: 'login',
					name: 'login',
					meta: {
						requiresAuth: false,
					},
					component: () => import('./layouts/authenticationTest')
				},
			]
		},
		{
			path: '/servererror',
			name: 'servererror',
			props: true,
			component: () => import('./components/Common/500'),
			meta: {
				requiresAuth: false,
			}
		},
		
		// {
		// 	path: '/privacyPolicy',
		// 	name: 'privacyPolicy',
		// 	props: false,
		// 	component: () => import('./views/GeneralPages/privacyPolicy.vue'),
		// 	meta: {
		// 		requiresAuth: false,
		// 	}
		// },
		{

			path: '/supportPage',
			name: 'supportPage',
			meta: {
				requiresAuth: false,
			},
			component: () => import('./views/GeneralPages/supportPage.vue')
		},
		{

			path: '/privacyPolicy',
			name: 'privacyPolicy',
			meta: {
				requiresAuth: false,
			},
			component: () => import('./views/GeneralPages/privacyPolicy')
		},

		{

			path: '/forgotPassword',
			name: 'forgotPassword',
			meta: {
				requiresAuth: false,
			},
			component: () => import('./views/GeneralPages/forgotPassword')
		},

		{

			path: '/resetPassword',
			name: 'resetPassword',
			meta: {
				requiresAuth: false,
			},
			component: () => import('./views/GeneralPages/resetPassword')
		},
		
		// {
		// 	path: '/map',
		// 	name: 'map',
		// 	props: true,
		// 	component: () => import('./views/Officers/Cases/map'),
		// 	meta: {
		// 		requiresAuth: false,
		// 	}
		// },
		{
			path: '*',
			name: '404pagenotfound',
			props: true,
			component: () => import('./components/Common/404'),
			meta: {
				requiresAuth: false,
			}
		},

	],
	scrollBehavior() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
	},
})

router.beforeEach((to, from, next) => {
	// 	if(!store.state.authentication)
	// 	{
	// Socket.authFunction()
	// 	}
	if (to.matched.some(route => route.meta.requiresAuth == true) && store.state.isLoggedIn == false) {
		console.log(from)
		next({ name: 'login', params: { lastPage: from } })
		return
	}
	// if (store.state.isLoggedIn) {
	// 	var url
	// 	if (store.state.userRole == 'official')
	// 		url = '/authenticate/official'
	// 	else
	// 		if (store.state.userRole == 'admin')
	// 			url = '/authenticate/admin'
	// 	axios({
	// 		method: 'POST',
	// 		url: url,
	// 		headers: {
	// 			'token': localStorage.getItem('token')
	// 		}
	// 	}).then(response => {
	// 		if (!response.data.status) {
	// 			store.commit("sessionOut", true)
	// 			return
	// 		}
	// 		// else {
	// 			// alert(response.data.msg)
	// 		// }
	// 	})
	// 		.catch(err => {
	// 			var msg = err;
	// 			console.log(msg)
	// 		});
	// }
	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'admin') {
		next({ name: 'sarpadashboard' })
		return
	}
	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'official') {
		next({ name: 'dashboard' })
		return
	}
	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'MedicalOfficer') {
		next({ name: 'dashboard' })
		return
	}

	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'District Emergency Officer') {
		next({ name: 'dashboard' })
		return
	}
	next()
})

export default router