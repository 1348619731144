<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Snake Reports List</span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start pt-2 class="subheading">
              <v-flex xs12 sm4 md4 lg3 >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date2"
                      placeholder="From Date" clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm4 md4 lg3  pl-md-2 pl-lg-2 pl-sm-2 pt-2 pt-lg-0 pt-md-0 pt-sm-0>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      placeholder="To Date"
                      readonly
                      v-bind="attrs" clearable
                      v-on="on"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
             
            </v-layout>
            <v-layout wrap justify-start pt-2 v-if="items && items.length > 0">
              <v-flex xs12>
                <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
                class="elevation-1 text--black"
                id="virtual-scroll-table"
                style="cursor: pointer;"
              >
                <template v-slot:[`item.createddate`]="{ item }">
                  <span>{{ formatDate(item.createddate) }}</span>
                </template>
            
                <template v-slot:[`item.issnakebite`]="{ item }">
                  <span v-if="item.issnakebite">Snakebite Case</span>
                  <span v-else>Just for Knowledge</span>
                </template>
            
                <template v-slot:[`item.photo`]="{ item }">
                  <span>
                    <img
                      contain
                      height="70px"
                      width="70px"
                      :src="mediaURL + item.photo"
                      fill-height
                      v-viewer="{ movable: false }"
                    />
                  </span>
                </template>
            
                <template  v-slot:[`item._id`]="{ item }">
                  <v-icon
                    small
                    color="success"
                    class="ml-3"
                    @click="openviewdetails(item)"
                  >
                    mdi-eye
                  </v-icon>
                  <!-- <v-icon
                    small
                    color="red"
                    class="ml-4"
                    @click="opendeleteDialog(item)"
                  >
                    mdi-delete
                  </v-icon> -->
                </template>
              </v-data-table>

              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              :total-visible="7"
              circle
              color="#427D2D"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else pt-16>
        <v-layout
          wrap
          justify-center
          align-center
         
        >
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found !</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to reject?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="rejectDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="rejectoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 10,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: null,
      menu: false,
      // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: null,
      menu2: false,
      headers: [
        { text: "Reported Date", value: "createddate", width: "200px" },
        { text: "Is snakebite", value: "issnakebite", width: "200px" },
        { text: "Images", value: "photo", width: "10px" },
        { text: "Actions", value: "_id", width: "10px" },
        ],

    };
  },
  beforeMount() {
    this.getData();
  },
 
  computed: {
    mainRole() {
      return this.$store.state.userRole;
    },
 
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
    date() {
      this.getData();
    },
    date2() {
      this.getData();
      this.date=""
    },
  },
  methods: {
    openviewdetails(item) {
      this.$router.push({
        path: "/disidentificationhelpview",
        query: {
          id: item._id,
        },
      });
    },

    opendeleteDialog(item) {
      this.deleteid = item._id;
      this.deleteDialog = true;
    },
    openrejectDialog(item) {
      this.rejectid = item._id;
      this.rejectDialog = true;
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/adminreply/all",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          count: this.count,
          fromDate: this.date2,
          toDate: this.date,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.Pagelength = response.data.pages;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    deleteoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/adminreply/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/removedeleterequest",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.rejectid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Rejected Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.rejectDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.tableheading{
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading{
  font-family: poppinsregular;
  font-size: 10px;
}
</style>